<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col key="higienizacoesPendentes" :cols="3">
          <v-card dark
          :loading="loaders.higienizacoesPendentes">
            <v-card-title>
              Higienizações Pendentes: {{higienizacoesPendentes}}
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col key="monstrosPendentes" :cols="3">
          <v-card dark
          :loading="loaders.monstrosPendentes">
            <v-card-title>
              Monstros Pendentes: {{monstrosPendentes}}
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col key="manutencoesPendentes" :cols="3">
          <v-card dark
          :loading="loaders.manutencoesPendentes">
            <v-card-title>
              Manutenções Pendentes: {{manutencoesPendentes}}
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col key="agendamentosPendentes" :cols="3">
          <v-card dark
          :loading="loaders.agendamentosPendentes">
            <v-card-title>
              Agendamentos Pendentes: {{agendamentosPendentes}}
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col key="Contentores" :cols="7" style="height: 100%">
          <v-card dark v-if="contentores">
            <v-card-title>
              <v-btn
                title="Selecionar Todos"
                v-if="contentoresselected.length == 0"
                class="mx-2"
                fab
                dark
                small
                color="#7366aa"
                @click="contentoresselected = contentoresFiltered">
                <v-icon dark>
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
              </v-btn>
              <v-btn
                title="Nenhum"
                v-else
                class="mx-2"
                fab
                dark
                small
                color="#7366aa"
                @click="contentoresselected = []">
                <v-icon dark>
                  mdi-checkbox-multiple-marked
                </v-icon>
              </v-btn>
              Contentores
              <v-btn
                v-if="permissao()"
                title="Adicionar Contentor"
                class="mx-2"
                fab
                dark
                small
                color="#7366aa"
                @click="
                  selecionado = {};
                  addContentor = true;">
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="pesquisaContentor"
                clearable
                flat
                solo-inverted
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Pesquisar">
              </v-text-field>
            </v-card-title>
            <v-card-title>
              <v-btn
                class="mx-2"
                v-bind:class="{ buttonOnClass: needClean}"
                dark
                small
                @click="getNeedClean(!needClean)">
                <v-icon dark>
                  mdi-spray-bottle
                </v-icon>
                Precisa Higienização
              </v-btn>
              <v-btn
                class="mx-2"
                v-bind:class="{ buttonOnClass: haveMonster}"
                dark
                small
                @click="getHaveMonster(!haveMonster)">
                <v-icon dark>
                  mdi-seat
                </v-icon>
                Tem Monstro
              </v-btn>
              <v-btn
                class="mx-2"
                v-bind:class="{ buttonOnClass: needMaintenance}"
                dark
                small
                @click="getNeedMaintenance(!needMaintenance)">
                <v-icon dark>
                  mdi-delete-forever
                </v-icon>
                Tem Anomalia
              </v-btn>
            </v-card-title>
            <v-data-table
              dark
              v-model="contentoresselected"
              :headers="contentorHeader"
              :items="contentoresFiltered"
              :loading="loaders.contentores || loaders.expand"
              loading-text="A carregar..."
              :search="pesquisaContentor"
              :custom-filter="filtroDaBusca"
              :single-select="false"
              :single-expand="true"
              :expanded.sync="expanded"
              show-expand
              @item-expanded="onExpand"
              item-key="id"
              show-select
              class="elevation-1 pb-5"
              :footer-props="{
                'items-per-page-options': [10],
              }"
              :items-per-page="8"
              hide-default-footer>
              <template v-slot:expanded-item="{ headers }">
                <td :colspan="(headers.length)">
                    <v-row class="ma-0">
                      <v-col class="text-center" :cols="2">
                        <b>Última Recolha:</b>
                        <div>
                          {{ ultimaRecolha }}
                        </div>
                      </v-col>
                      <v-col class="text-center" :cols="3">
                        <b>Última Higienização:</b>
                        <div>
                          {{ ultimaHigienizacao }}
                        </div>
                      </v-col>
                      <v-col class="text-center" :cols="2">
                        <b>Média:</b>
                        <div>
                          {{ calcAverage(mediasContentors) }}
                        </div>
                      </v-col>
                      <v-col class="align-top" cols="5">
                        <v-sheet
                          class="v-sheet--offset flex"
                          color="dark"
                          max-width="calc(100% )">
                          <v-sparkline
                            :labels="mediasContentors.map(a=>dateToDDMM(a.data))"
                            :value="mediasContentors.map(a=>a.nivel!=null?a.nivel:0)"
                            :gradient="['#f72047', '#ffd200', '#1feaea']"
                            color="white"
                            line-width="4"
                            padding="10"
                            height="50"
                          ></v-sparkline>
                        </v-sheet>
                    </v-col>
                    </v-row>
                  </td>
               </template>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.nivel`]="{ item }">
                <v-chip
                  v-if="item.sensor"
                  :color="getColorNivelSensorTabela(item.nivel)"
                  dark>
                  {{ item.nivel }}%
                </v-chip>
                <v-icon title="Sem sensor" color="grey" dark v-if="!item.sensor">
                  mdi-wifi
                </v-icon>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-icon
                  v-if="item.status"
                  title="Contentor Ativo"
                  color="green">
                  mdi-toggle-switch
                </v-icon>
                <v-icon
                  v-if="!item.status"
                  title="Contentor Inativo"
                  color="grey"
                  dark>
                  mdi-toggle-switch-off
                </v-icon>
              </template>
              <template v-slot:[`item.editar`]="{ item }">
                <v-btn
                  v-if="permissao()"
                  class="mx-2"
                  fab
                  dark
                  small
                  color="#7366aa"
                  @click="editarContentor(item)">
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.position`]="{ item }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="#7366aa"
                  @click="centerZoom(item.position);contentoresselected=[];contentoresselected.push(item);">
                  <v-icon dark>
                    mdi-magnify-plus
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col :cols="5">
          <v-card style="height: 650px; width: 100%" dark>
            <v-card-title>
              Mapa
              <v-spacer></v-spacer>
            </v-card-title>
            <l-map
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              @update:center="centerUpdate"
              v-on:update:zoom="updateZoom($event)"
              ref="mymap"
            >
              <l-geo-json
                v-if="geojson"
                :geojson="geojson"
                :options-style="myStyle"
              ></l-geo-json>
              <l-tile-layer :url="url" :attribution="attribution" />
              <template v-if="camioes">
                <l-marker
                  v-for="marker in camioes"
                  :key="`camioes-${marker.id}`"
                  :lat-lng="getlatLng(marker.lat, marker.lng)">
                  <l-icon
                    :icon-size="dynamicSizeCamiao"
                    :icon-anchor="dynamicAnchorCamiao"
                    :icon-url="API_URL+'/img/caminhao/normal.png'"/>
                  <l-popup>
                    <div v-if="marker.data_cartrack">
                      Caminhao: {{ marker.matricula }}
                      <br />
                      Última Atualização:
                      {{
                        marker.data_cartrack.split("-")[2].substr(0, 2) +
                        "/" +
                        marker.data_cartrack.split("-")[1] +
                        "/" +
                        marker.data_cartrack.split("-")[0] +
                        " " +
                        marker.data_cartrack.split("-")[2].substr(2, 9)
                      }}
                      <br />
                      <p>
                        <br />
                      </p>
                    </div>
                  </l-popup>
                </l-marker>
              </template>
              <l-marker
                :lat-lng="getlatLng(41.38150949313275, -8.380767029624579)">
                <l-icon
                  :icon-size="dynamicSizeCamiao"
                  :icon-anchor="dynamicAnchorCamiao"
                  :icon-url="API_URL+'/img/resinorte.png'"
                />
                <l-popup>
                  <div>Resinorte</div>
                </l-popup>
              </l-marker>
              <l-marker
                v-for="(marker, i) in contentoresselected"
                :key="`contentors-${i}`"
                :visible="marker.position != null"
                :draggable="marker.draggable"
                :lat-lng.sync="marker.position"
              >
                <l-icon
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                  :icon-url="
                    API_URL+'/img/' +
                    getColorNivelSensor(marker.nivel) +
                    '.png'
                  "
                />
                <l-popup>
                  <div>
                    Contentor: {{ marker.numero_contentor }}
                    <br />
                    <v-chip
                      :color="getColorNivelSensorTabela(marker.nivel)"
                      dark
                      small
                      >{{ marker.nivel }}%</v-chip
                    >
                    <p>
                      Rua: {{ marker.rua }}
                      <br />
                      Freguesia: {{ marker.localidade.designacao }}
                      <br />
                    </p>
                  </div>
                </l-popup>
              </l-marker>
            </l-map>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br><br>
    <div class="text-center">
      <v-dialog v-model="addContentor" style="z-index: 9999" width="500">
        <v-card :disabled = "loaders.savingContentor">
          <v-card-title class="headline grey lighten-2" primary-title>
            Contentor
          </v-card-title>
          <v-card-text>
            <v-form ref="formContentores" lazy-validation>
              <v-text-field
                v-model="selecionado.numero_contentor"
                :counter="10"
                label="Nº Contentor"
                :rules="[rules.required]">
              </v-text-field>
              <v-text-field
                v-model="selecionado.rua"
                :counter="60"
                label="Rua"
                :rules="[rules.required]">
              </v-text-field>
              <v-text-field
                v-model="selecionado.referencia"
                :counter="40"
                label="Referência">
              </v-text-field>
              <v-select
                v-model="selecionado.localidade"
                :items="freguesias"
                item-text="designacao"
                label="Freguesia"
                return-object
                :rules="[rules.required]">
              </v-select>
              <v-text-field
                v-model="selecionado.lat"
                :counter="15"
                label="Latitude"
                :rules="[rules.required]">
              </v-text-field>
              <v-text-field
                v-model="selecionado.lng"
                :counter="15"
                label="Longitude"
                :rules="[rules.required]">
              </v-text-field>
              <v-switch
                v-model="selecionado.status"
                :label="`${
                  selecionado.status
                    ? 'Desativar contentor'
                    : 'Ativar contentor'
                }`">
              </v-switch>
              <v-btn
                color="success"
                class="mr-4"
                @click="validateForm"
                :loading = "loaders.savingContentor"
                :disabled = "loaders.savingContentor">
                Salvar
              </v-btn>
              <v-btn color="error" class="mr-4" @click="addContentor = false">
                Cancelar
              </v-btn>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import mixin from '../plugins/mixin'
import { icon, latLng } from "leaflet";
/* eslint-disable */
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LGeoJson,
  LIcon,
  LPolyline,
} from "vue2-leaflet";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "Contentores",
  mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
    LIcon,
    LPolyline,
  },
  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    menuDataRota: "",
    pesquisaContentor: "",
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 10,
      totalItems: 0,
      rowsPerPageItems: [10],
    },
    rules: {
      required: (value) => !!value || "Preenchimento obrigatório",
    },
    addContentor: false,
    dataPercurso: new Date().toISOString().substr(0, 10),
    dateFormatted: new Date(),
    myStyle: {
      weight: 2,
      color: "rgb(142, 140, 140)",
      fillColor: "#5f5f5f",
    },
    rotas: [],
    search: "",
    contentorHeader: [
      { text: "Nº", value: "numero_contentor", align: 'center' },
      { text: "Rua", value: "rua", align: 'center' },
      { text: "Freguesia", value: "localidade.designacao", align: 'center' },
      { text: "Nível (%)", value: "nivel", align: 'center' },
      { text: "Status ", value: "status", sortable: true, align: 'center' },
      { text: "Mapa ", value: "position", sortable: false, align: 'center' },
      { text: "Editar", value: "editar", sortable: true, align: 'center' },
    ],
    contentoresselect: false,
    contentoresselected: [],
    contentores: [],
    contentoresFiltered: [],
    selecionado: {},
    needClean: false,
    haveMonster: false,
    needMaintenance: false,
    camioes: [],
    ultimaRecolha: 0,
    ultimaHigienizacao:0,
    mediasContentors:[],
    higienizacoesPendentes: 0,
    monstrosPendentes: 0,
    manutencoesPendentes: 0,
    agendamentosPendentes: 0,
    markers: [
      {
        id: "m1",
        position: { lat: 47.41322, lng: -1.2 },
        tooltip: "tooltip for marker1",
        icon: icon.glyph({
          prefix: "mdi",
          glyph: "trash-can-outline",
        }),
      },
    ],
    zoom: 11,
    center: latLng(41.460349, -8.3771063, 12),
    url:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png",
    attribution: "",
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    currentZoom: 11.5,
    currentCenter: latLng(47.41322, -1.219482),
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    geojson: null,
    iconSize: 30,
    iconSizeCamiao: 30,
    expanded: [],
    freguesias: [],
    loaders: {
      higienizacoesPendentes: false,
      monstrosPendentes: false,
      manutencoesPendentes: false,
      agendamentosPendentes: false,
      contentores: false,
      savingContentor: false,
      expand: false,
    }
  }),
  watch: {
    date() {
      this.dataPercurso = this.formatDate(this.date);
    },
  },
  methods: {
    filtroDaBusca(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        item.valoresDePesquisa.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '').indexOf(search.toLowerCase().normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')) !== -1
       // nomalize(NFD) e replace() estao a remover tudo que nao for 0-9 e o que nao for A-Z a-z, ou seja, removendo acentos e caracteres especiais no campo de pesquisa//
      );
    },
    saveContentor() {
      this.loaders.savingContentor = true;
      if (this.selecionado.lng)
        this.selecionado.lng = (this.selecionado.lng + "").replace(",", ".");
      if (this.selecionado.lat)
        this.selecionado.lat = (this.selecionado.lat + "").replace(",", ".");
      if (this.selecionado.id)
        this.$http
          .put("contentors/" + this.selecionado.id, this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then(() => {
            this.$refs.formContentores.reset();
            this.getContentors();
            this.addContentor = false;
            this.loaders.savingContentor = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaders.savingContentor = false;
          });
      else
        this.$http
          .post("contentors/", this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then(() => {
            this.$refs.formContentores.reset();
            this.getContentors();
            this.addContentor = false;
            this.loaders.savingContentor = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaders.savingContentor = false;
          });
    },
    editarContentor(item) {
      this.selecionado = item;
      this.addContentor = true;
    },
    validateForm() {
      if (this.$refs.formContentores.validate()) {
        this.saveContentor();
      }
    },
    getlatLng: function (lat, lng) {
      return { lat, lng };
    },
    updateZoom: function (e) {
      this.zoom = e;
    },
    centerZoom(position) {
      this.zoom = 17;
      this.zoomUpdate = 17;
      this.center = latLng(0, 0);
      setTimeout(() => {
        this.center = position;
      }, 500);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    imagemFoto(imagem) {
      return this.API_URL+`${imagem}`;
    },
    permissao() {
      return this.getObjeto("user").role.name == "Administrador";
    },
    error401logout(error) {
      if (error.status == 401) {
        this.dialog = false;
        localStorage.clear();
        this.$router.push("/login");
      }
    },
    getContentors() {
      this.loaders.contentores = true;
      this.contentores = [];
      this.contentoresFiltered = [];
      this.$http
        .get("contentors?_limit=10000", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          response.data.forEach((i) => {
            i.position = latLng(i.lat, i.lng);
            i.valoresDePesquisa =
              i.numero_contentor + "  " + i.rua + " " + i.localidade.designacao;
            this.contentores.push(i);
            this.center = this.contentores[0].position;
            //this.contentoresselected = this.contentores;
          });
          this.contentoresFiltered = this.contentores;
          this.loaders.contentores = false;
        })
        .catch((error) => {
          this.error401logout(error.response);
          this.loaders.contentores = false;
        });
    },
    getNeedClean(param){
      this.needClean = param;
      this.haveMonster = false;
      this.needMaintenance = false;
      if (param == true){
        this.$http
          .get("contentors/needClean/"+(param?1:0), {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {
            var resp = response.data;
            this.contentoresFiltered = this.contentores.filter(function (obj1) {
              return resp.some(function(obj2){
                return obj1.id === obj2.contentor;
              })
            });
            this.contentoresselected = this.contentoresFiltered;
          })
          .catch(() => {
          });
      }else{
        this.contentoresFiltered = this.contentores;
        this.contentoresselected = [];
      }
    },
    getHaveMonster(param){
      this.haveMonster = param;
      this.needClean = false;
      this.needMaintenance = false;
      if (param == true){
        this.$http
          .get("contentors/haveMonster/"+(param?1:0), {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {
            var resp = response.data;
            this.contentoresFiltered = this.contentores.filter(function (obj1) {
              return resp.some(function(obj2){
                return obj1.id === obj2.contentor;
              })
            });
            this.contentoresselected = this.contentoresFiltered;
          })
          .catch(() => {
          });
      }else{
        this.contentoresFiltered = this.contentores;
        this.contentoresselected = [];
      }
    },
    getNeedMaintenance(param){
      this.needMaintenance = param;
      this.haveMonster = false;
      this.needClean = false;
      if (param == true){
        this.$http
          .get("contentors/needMaintenance/"+(param?1:0), {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then((response) => {
            var resp = response.data;
            this.contentoresFiltered = this.contentores.filter(function (obj1) {
              return resp.some(function(obj2){
                return obj1.id === obj2.contentor;
              })
            });
            this.contentoresselected = this.contentoresFiltered;
          })
          .catch(() => {
          });
      }else{
        this.contentoresFiltered = this.contentores;
        this.contentoresselected = [];
      }
    },
    getCamioes() {
      this.$http
        .get("camioes", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.camioes = response.data;
        });
    },
    onExpand(item){
      if (item.value) {
        this.loaders.expand = true,
        this.ultimaRecolha = 0,
        this.ultimaHigienizacao = 0,
        this.mediasContentors = [],
        this.lastRecolha(item.item);
        this.lastHigienizacao(item.item);
      }
    },
    lastRecolha(item){
      let nDays = 10;
      let today = this.dateToYYYYMMDD(new Date());
      let prev = this.dateToYYYYMMDD(this.subtractDays(nDays));

      this.$http
        .get('graphql?query={ \
          reports(where: { \
            contentor: ' + item.id + ', \
            recolheuLixo: true, \
            data_gte: "'+prev+'T00:00:00.000Z", \
            data_lte: "'+today+'T23:59:59.000Z" \
            }, sort:\"data:desc\"){nivel, data}}',
          {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          }
          )
          .then((response) => {
            let temp = response.data.data.reports[0];
            this.ultimaRecolha = temp ? this.dateToYYYYMMDDHHMM(temp.data) : "Não Disponível";
            try {
              for (let i=nDays-1; i>=0; i--) {
                let day = this.dateToYYYYMMDD(this.subtractDays(i));
                let rep = response.data.data.reports.find((report) => this.dateToYYYYMMDD(report.data) == day);
                if (!rep) {
                  rep = {nivel: null, data: day};
                }
                this.mediasContentors.push(rep);
              }
            } catch (error) {
              console.log(error);
            }
            this.loaders.expand = false;
          })

    },
    lastHigienizacao(item){
    this.$http
      .get("graphql?query={ \
        reports(where: {contentor: " + item.id + " fezHigenizacao: true, }, sort:\"data:desc\", limit: 1){nivel, data,\
        }}",
        {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        }
        )
        .then((response) => {
          let temp = response.data.data.reports[0];
          this.ultimaHigienizacao = temp ? this.dateToYYYYMMDDHHMM(temp.data): "Não Disponível";
          this.loaders.expand = false;
        })
    },
    calcAverage(array) {
      let nElem = 0;
      let media = (array.reduce((a,b) => {
        if (b.nivel != null) {
          nElem++;
          return a + b.nivel;
        } else {
          return a;
        }
      }, 0) / nElem);

      return media ? media.toFixed(2)+"%" : "Não Disponível";
    },
    getPendentes(){
      this.loaders.higienizacoesPendentes = true;
      this.loaders.monstrosPendentes = true;
      this.loaders.manutencoesPendentes = true;
      this.loaders.agendamentosPendentes = true
      this.$http
        .get("/contentors/needClean/1", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.higienizacoesPendentes = response.data.length;
          this.loaders.higienizacoesPendentes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.higienizacoesPendentes = false;
        });
      this.$http
        .get("/contentors/haveMonster/1", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.monstrosPendentes = response.data.length;
          this.loaders.monstrosPendentes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.monstrosPendentes = false;
        });
      this.$http
        .get("/contentors/needMaintenance/1", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.manutencoesPendentes = response.data.length;
          this.loaders.manutencoesPendentes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.manutencoesPendentes = false;
        });
      this.$http
        .get('graphql?query={ \
          agendamentosConnection (where: { _or: [{recolhido: false}, {recolhido_null: true}] cancelado: false}) { \
            aggregate { \
              count } \
          } \
        }', {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((response) => {
          this.agendamentosPendentes = response.data.data.agendamentosConnection.aggregate.count;
          this.loaders.agendamentosPendentes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaders.agendamentosPendentes = false;
        });
    },
  },
  created() {
    this.getCamioes();
    this.getFreguesias().then((resp) => {
      this.freguesias = resp.data.data.freguesias
    });
    this.$http
      .get(this.API_URL+"/guimaraes.geojson")
      .then((response) => {
        this.geojson = response.data;
      });
    this.getContentors();
    this.getPendentes();
  },
  mounted() {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 500);

    const map = this.$refs.mymap.mapObject;
    map.addControl(new L.Control.Fullscreen());
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },

    dynamicSizeCamiao() {
      return [this.iconSizeCamiao, this.iconSizeCamiao * 1.15];
    },
    dynamicAnchorCamiao() {
      return [this.iconSizeCamiao / 2, this.iconSizeCamiao * 1.15];
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
};
</script>
<style scoped>
  thead tr {
    background-color: #303030;
  }

  .v-data-table-header {
    background-color: #303030;
  }
  .v-data-footer__select {
    min-width: 3em;
    display: none;
  }
</style>
<style>
.buttonOnClass{
  background-color: #7366aa !important;
}
  .v-data-footer__select {
    min-width: 3em;
    display: none;
  }
</style>
